import React, { useRef } from 'react';
import { Grid, Icon } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import {
  findOnArray,
  summarizer,
  validarCPF,
  validarCNPJ,
} from 'utils/functions';
import { toastWarning } from 'utils/toast';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';
import api from 'services/api';
import moment from 'moment';

const Cheque = ({
  tab,
  drops,
  valor,
  onSubmit,
  isCheque,
  isPagamento,
  isRecebimento,
}) => {
  const forma_pagto_id =
    drops?.FormaDePagamento?.find(
      (f) =>
        f?.modulo === 'CHEQUES' &&
        f?.label?.includes('SUBST') &&
        f?.modalidade === '02-CHEQUE'
    )?.value || null;
  const defaultValues = {
    forma_pagto_id: tab === 7 ? forma_pagto_id : null,
    valor: valor || '',
    banda: '',
    banco_id: null,
    agencia: '',
    conta: '',
    cheque: '',
    nome: '',
    cidade: '',
    dtemissao: null,
    dtvenc: null,
    tipo_documento_id: null,
    numdoc: '',
    observacao: '',
    cheques: [],
  };
  const { control, handleSubmit, watch, setValue, getValues } = useForm({
    defaultValues,
  });
  const valorRef = useRef();
  const drop = drops?.FormaDePagamento?.filter((f) => {
    if (isPagamento) {
      return (
        f?.modalidade === '02-CHEQUE' &&
        f?.modulo !== 'VENDAS' &&
        f?.modulo !== 'CHEQUES'
      );
    } else if (isRecebimento) {
      return (
        f?.modalidade === '02-CHEQUE' &&
        f?.modulo !== 'COMPRAS' &&
        f?.modulo !== 'CHEQUES'
      );
    } else if (isCheque) {
      return f?.modalidade === '02-CHEQUE' && f?.modulo === 'CHEQUES';
    } else {
      return f?.modalidade === '02-CHEQUE';
    }
  });

  const onAddBanda = async (e) => {
    const banda = e?.target?.value?.replace(/\D/g, '');
    if (banda?.length === 30) {
      const banco = banda?.substring(0, 3);
      const agencia = banda?.substring(3, 7);
      const cheque = banda?.substring(11, 17);
      const cc = banda?.substring(23, 28);
      const verificador3 = banda?.substring(28, 29);

      let conta = '';
      if (Boolean(cc)) conta = conta + cc;
      if (Boolean(cc) && Boolean(verificador3)) conta = conta + '-';
      if (Boolean(verificador3)) conta = conta + verificador3;

      const banco_id =
        drops?.EntidadeTipo?.filter(
          (f) => f?.tipo === 'INSTITUICAO FINANCEIRA'
        )?.find((s) => s?.Código == banco)?.value || null;

      setValue('banda', banda || '');
      setValue('banco_id', banco_id || '');
      setValue('conta', conta || '');
      setValue('agencia', agencia || '');
      setValue('cheque', cheque || '');

      const { data } = await api.get(`/Financeiro/Pendencias/Cheque`, {
        params: { banda },
      });

      setValue('nome', data?.nome || '');
      setValue('tipo_documento_id', data?.tipo_documento_id || null);
      setValue('numdoc', data?.numdoc || '');
      setValue('cidade', '');
      setValue('dtemissao', null);
      setValue('dtvenc', null);
      setValue('observacao', '');
    }
  };

  const onAddCheque = ({ cheques, ...rest }) => {
    if (!Boolean(rest?.forma_pagto_id)) {
      return toastWarning('Selecione uma forma de pagamento');
    }
    if (Boolean(rest?.valor <= 0)) {
      return toastWarning('Valor inválido');
    }
    if (rest?.tipo_documento_id === 1 && !validarCNPJ(rest?.numdoc)) {
      return toastWarning('CNPJ inválido');
    }
    if (rest?.tipo_documento_id === 2 && !validarCPF(rest?.numdoc)) {
      return toastWarning('CPF inválido');
    }
    if (cheques?.some((c) => c?.forma_pagto_id !== rest?.forma_pagto_id)) {
      setValue('cheques', [{ id: 0, ...rest }]);
    } else {
      setValue('cheques', [...cheques, { id: cheques?.length, ...rest }]);
    }
    valorRef?.current?.focus();
  };

  const onAddLancamento = ({ forma_pagto_id, valor, cheques }) => {
    if (Boolean(cheques?.length)) {
      const data = {
        valor: summarizer(cheques, 'valor'),
        cheques,
        forma_pagto_id: cheques[0]?.forma_pagto_id,
        forma_pagto: findOnArray(cheques[0]?.forma_pagto_id, drop, 'label'),
      };
      onSubmit(data);
    } else {
      if (!Boolean(forma_pagto_id)) {
        return toastWarning('Selecione uma forma de pagamento');
      }
      if (Boolean(valor <= 0)) {
        return toastWarning('Valor inválido');
      }
      const data = {
        valor,
        forma_pagto_id,
        forma_pagto: findOnArray(forma_pagto_id, drop, 'label'),
      };
      onSubmit(data);
    }
  };

  const loadCheque = async () => {
    const { banco_id, agencia, conta } = getValues();
    if (Boolean(banco_id) && Boolean(agencia) && Boolean(conta)) {
      const { data } = await api.get(`/Financeiro/Pendencias/Cheque`, {
        params: { banco_id, agencia, conta },
      });

      if (data?.nome) {
        setValue('nome', data?.nome || '');
      }
      if (data?.tipo_documento_id) {
        setValue('tipo_documento_id', data?.tipo_documento_id || null);
      }
      if (data?.numdoc) {
        setValue('numdoc', data?.numdoc || '');
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="forma_pagto_id"
          control={control}
          label="Forma de Pagamento"
          options={drop}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask
          name="valor"
          control={control}
          label="Valor"
          inputRef={valorRef}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input
          name="banda"
          control={control}
          label="Banda"
          onBlur={onAddBanda}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Dropdown
          name="banco_id"
          control={control}
          label="Banco"
          options={drops?.EntidadeTipo?.filter(
            (f) => f?.tipo === 'INSTITUICAO FINANCEIRA'
          )}
          onBlur={loadCheque}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input
          name="agencia"
          control={control}
          label="Agência"
          type="number"
          onBlur={loadCheque}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input
          name="conta"
          control={control}
          label="Conta"
          onBlur={loadCheque}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input
          name="cheque"
          control={control}
          label="Nº do Cheque"
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input name="nome" control={control} label="Nome" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input name="cidade" control={control} label="Cidade" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask
          name="dtemissao"
          control={control}
          label="Data de Emissão"
          type="date"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask
          name="dtvenc"
          control={control}
          label="Data de Vencimento"
          type="date"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="tipo_documento_id"
          control={control}
          label="Tipo de Documento"
          options={drops?.TipoDocumento}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          name="numdoc"
          control={control}
          label="Nº do Documento"
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          name="observacao"
          control={control}
          label="Observação"
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSubmit(onAddCheque)}
        >
          Adicionar
        </Button>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={watch('cheques')}
          columns={[
            {
              field: 'nome',
              headerName: 'Nome',
              flex: 3,
              sortable: false,
            },
            {
              field: 'cheque',
              headerName: 'Nº do Cheque',
              flex: 2,
              sortable: false,
            },
            {
              field: 'dtvenc',
              headerName: 'Data de Vencimento',
              flex: 1,
              sortable: false,
              valueGetter: ({ value }) =>
                value && moment(value).format('DD/MM/YYYY'),
            },
            {
              field: 'banco',
              headerName: 'Banco',
              flex: 1,
              sortable: false,
              valueGetter: ({ row }) =>
                findOnArray(row?.banco_id, drops?.EntidadeTipo, 'label'),
            },
            {
              field: 'agencia',
              headerName: 'Agência',
              flex: 1,
              sortable: false,
            },
            {
              field: 'conta',
              headerName: 'Conta',
              flex: 1,
              sortable: false,
            },
            {
              field: 'valor',
              headerName: 'Valor',
              type: 'number',
              flex: 1,
              sortable: false,
              valueGetter: ({ value }) =>
                value &&
                value?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                }),
            },
            {
              field: 'actions',
              headerName: 'Ações',
              type: 'actions',
              flex: 1,
              getActions: ({ row }) => [
                <GridActionsCellItem
                  icon={<Icon>delete</Icon>}
                  label="Excluir"
                  onClick={() =>
                    setValue(
                      'cheques',
                      watch('cheques')
                        ?.filter((f) => f?.id !== row?.id)
                        ?.map((m, id) => ({ ...m, id }))
                    )
                  }
                />,
              ],
            },
          ]}
          hideFooter
          autoHeight
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          localeText={{ noRowsLabel: 'Nenhum Registro.' }}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onAddLancamento)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};
export default Cheque;
